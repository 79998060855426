.blockquote{
    &.blockquote-info{
        border-color: $info-color;
        color: $info-color;

        small{
            color: $info-color;
        }
    }

    &.blockquote-info-inv{
        border-color: $info-color;
        //color: $info-color;
        color: $white-color;
        background-color: $info-color;

        small{
            color: $info-color;
        }
    }

    &.blockquote-info-hi{
        border_color: $info-hi-color;
        //color: $info-color;
        color: $white-color;
        background-color: $info-hi-color;

        small{
            color: $info-color;
        }
    }
}
